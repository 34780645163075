// composables/useHomemasterGlobalStore.ts

import { useState } from '#app';

interface HMGlobal {
    title?: string;
    description?: string;
    logo: string;
    logoInside: string;
}

interface HMHeading {
    headingImage: string;
    headingTitle: string;
    headingSubTitle: string;
    headingCTA: string;
    headingSocialTitle: string;

}

interface HMForm {
    formTitle: string
    formSubTitle: string
    fullName: string
    fullNameValidationText: string
    emailAddress: string,
    emailAddressValidationText: string
    message: string
    messageValidationText: string
    sendButtonText:string
    contactServicesTitle: string[]
    contactServices: string[]
}

interface HMContact{
    title?: string;
    subTitle?: string;
    icon: string[];
    city: string;
    street: string;
    phone: string;
    email: string;
}



export function useHMGlobalStore() {
    // State
    const globalItem = useState<HMGlobal | null>('globalItem', () => null);
    const headingItem = useState<HMHeading | null>('headingItem', () => null);
    const formItem = useState<HMForm | null>('formItem', () => null);
    const contactItem = useState<HMContact | null>('contactItem', () => null);


    // Actions

    const setGlobalItem = (item: HMGlobal | null) => {
        globalItem.value = item;
    };
    const fetcHMGlobalData = async () => {
        if (!globalItem.value) {
            try {
                const { getSingletonItem } = useDirectusItems();
                const data = await getSingletonItem<HMGlobal>({ collection: "globalHomeMaster" });
                setGlobalItem(data);
            } catch (e) {
                console.error("Failed to fetch data:", e);
                setGlobalItem(null); // Set to null to indicate failure
            }
        }
    };
// ************************
    const setHeadingItem = (item: HMHeading | null) => {
        headingItem.value = item;
    };
    const fetchHMHeadingData = async () => {
        if (!headingItem.value) {
            try {
                const { getSingletonItem } = useDirectusItems();
                const data = await getSingletonItem<HMHeading>({ collection: "headingHomeMaster" });
                console.log('Fetched heading data:', data); // Add this line for debugging
                setHeadingItem(data);
            } catch (e) {
                console.error("Failed to fetch data:", e);
                setHeadingItem(null); // Set to null to indicate failure
            }
        }
    };

    // *****************
    const setFormItem = (item: HMForm | null) => {
        formItem.value = item;
    };
    const fetchHMFormData = async () => {
        if (!formItem.value) {
            try {
                const { getSingletonItem } = useDirectusItems();
                const data = await getSingletonItem<HMForm>({ collection: "contactFormHomeMaster" });
                console.log('Fetched form data:', data); // Add this line for debugging
                setFormItem(data);
            } catch (e) {
                console.error("Failed to fetch data:", e);
                setFormItem(null); // Set to null to indicate failure
            }
        }
    };

    // *****************
    const setContactItem = (item: HMContact | null) => {
        contactItem.value = item;
    };
    const fetchHMContactData = async () => {
        if (!contactItem.value) {
            try {
                const { getSingletonItem } = useDirectusItems();
                const data = await getSingletonItem<HMContact>({ collection: "contactHomeMaster" });
                console.log('Fetched form data:', data); // Add this line for debugging
                setContactItem(data);
            } catch (e) {
                console.error("Failed to fetch data:", e);
                setContactItem(null); // Set to null to indicate failure
            }
        }
    };




    // Getters
    const title = computed(() => globalItem.value?.title || 'loading');
    const description = computed(() => globalItem.value?.description || 'loading');
    const logo = computed(() => globalItem.value?.logo || 'loading');
    const logoInside = computed(() => globalItem.value?.logoInside || 'loading');


    const headingImage = computed(() => headingItem.value?.headingImage || 'loading');
    const headingTitle = computed(() => headingItem.value?.headingTitle || 'loading');
    const headingSubTitle = computed(() => headingItem.value?.headingSubTitle || 'loading');
    const headingCTA = computed(() => headingItem.value?.headingCTA || 'loading');
    const headingSocialTitle = computed(() => headingItem.value?.headingSocialTitle || 'loading');


    const  formTitle  = computed(() => formItem.value?.formTitle || 'loading');
    const   formSubTitle = computed(() => formItem.value?.formSubTitle || 'loading');
    const   fullName = computed(() => formItem.value?.fullName || 'loading');
    const   fullNameValidationText = computed(() => formItem.value?.fullNameValidationText || 'loading');
    const   emailAddress = computed(() => formItem.value?.emailAddress || 'loading');
    const   emailAddressValidationText = computed(() => formItem.value?.emailAddressValidationText || 'loading');
    const    message = computed(() => formItem.value?.message || 'loading');
    const    messageValidationText = computed(() => formItem.value?.messageValidationText || 'loading');
    const   sendButtonText = computed(() => formItem.value?.sendButtonText || 'loading');
    const   contactServicesTitle = computed(() => formItem.value?.contactServicesTitle || 'loading');
    const   contactServices = computed(() => formItem.value?.contactServices || 'loading');

    const   contactTitle = computed(() => contactItem.value?.title || 'loading');
    const   contactDescription = computed(() => contactItem.value?.subTitle || 'loading');
    const   contactIcon = computed(() => contactItem.value?.icon || 'loading');
    const contactCity = computed(() => contactItem.value?.city || 'loading');
    const contactStreet = computed(() => contactItem.value?.street || 'loading');
    const contactPhone = computed(() => contactItem.value?.phone || 'loading');
    const contactEmail = computed(() => contactItem.value?.email || 'loading');

    return {
        globalItem,
        fetcHMGlobalData,
        title,
        description,
        logo,
        logoInside,

        headingItem,
        fetchHMHeadingData,
        headingImage,
        headingTitle,
        headingSubTitle,
        headingCTA,
        headingSocialTitle,


        fetchHMFormData,
        formTitle,
        formSubTitle,
        fullName,
        fullNameValidationText,
        emailAddress,
        emailAddressValidationText,
        message,
        messageValidationText,
        sendButtonText,
        contactServices,
        contactServicesTitle,


        fetchHMContactData,
        contactTitle,
        contactDescription,
        contactIcon,
        contactCity,
        contactStreet,
        contactPhone,
        contactEmail
    }
}
